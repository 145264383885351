import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import Aura from '@primeng/themes/aura';
import { providePrimeNG } from 'primeng/config';
import { appRoutes } from './app.routes';
import { API_BASE_URL } from './core/services/service-proxies';
import { environment } from '../environments/environment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiErrorInterceptor } from './core/interceptors/api-error.interceptor';
import { DialogService } from 'primeng/dynamicdialog';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(
            appRoutes,
            withInMemoryScrolling({
                anchorScrolling: 'enabled',
                scrollPositionRestoration: 'enabled'
            }),
            withEnabledBlockingInitialNavigation()
        ),
        provideAnimationsAsync(),
        providePrimeNG({
            theme: {
                preset: Aura,
                options: {
                    darkModeSelector: '.app-dark'
                }
            }
        }),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: API_BASE_URL,
            multi: false,
            useValue: environment.apiUrl
        },
        MessageService,
        DialogService,
        ConfirmationService,
        { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true }
    ]
};
