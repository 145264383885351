import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { MenuitemComponent } from '../menu-item/menu-item.component';

@Component({
    selector: 'app-menu',
    templateUrl: 'menu.component.html',
    imports: [CommonModule, RouterModule, MenuitemComponent]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AppMenu implements OnInit {
    menuItems: MenuItem[] = [];

    ngOnInit(): void {
        this.menuItems = [
            {
                id: 'home_item',
                label: 'Home',
                items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] }]
            },
            {
                id: 'administration_item',
                label: 'Administration',
                items: [
                    {
                        label: 'Currencies',
                        icon: 'pi pi-fw pi-dollar',
                        routerLink: ['features/currencies']
                    },
                    {
                        label: 'Departments',
                        icon: 'pi pi-fw pi-sitemap',
                        routerLink: ['features/departments']
                    },
                    {
                        label: 'Jobs',
                        icon: 'pi pi-fw pi-wallet',
                        routerLink: ['features/jobs']
                    }
                ]
            }
        ];
    }
}
