import { Component } from '@angular/core';
import { Button } from 'primeng/button';
import { ConfirmDialog } from 'primeng/confirmdialog';

@Component({
    selector: 'app-confirm-dialog',
    imports: [Button, ConfirmDialog],
    templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {}
