<div class='layout-wrapper' [ngClass]='containerClass'>
  <app-topbar></app-topbar>
  <app-sidebar></app-sidebar>
  <app-confirm-dialog></app-confirm-dialog>
  <div class='layout-main-container'>
    <div class='layout-main'>
      <p-toast></p-toast>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <div class='layout-mask animate-fadein'></div>
</div>
