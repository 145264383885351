<div class="layout-topbar">
    <div class="layout-topbar-logo-container">
        <button class="layout-menu-button layout-topbar-action" (click)="layoutService.onMenuToggle()">
            <i class="pi pi-bars"></i>
        </button>
        <a class="layout-topbar-logo" routerLink="/">
            <p-image src="/HR_Management_System_Logo.webp" width="32"></p-image>
            <span>HRM</span>
        </a>
    </div>
    <div class="layout-topbar-actions">
        <div class="layout-config-menu">
            <button type="button" class="layout-topbar-action" (click)="toggleDarkMode()">
                <i [ngClass]="{ 'pi ': true, 'pi-moon': layoutService.isDarkTheme(), 'pi-sun': !layoutService.isDarkTheme() }"></i>
            </button>
        </div>

        <button
            #topbarmenubutton
            class="layout-topbar-menu-button layout-topbar-action"
            pStyleClass="@next"
            enterFromClass="hidden"
            enterActiveClass="animate-scalein"
            leaveToClass="hidden"
            leaveActiveClass="animate-fadeout"
            [hideOnOutsideClick]="true"
        >
            <i class="pi pi-ellipsis-v"></i>
        </button>

        <div #topbarmenu class="layout-topbar-menu hidden lg:block">
            <div class="layout-topbar-menu-content">
                <button type="button" class="layout-topbar-action">
                    <i class="pi pi-calendar"></i>
                    <span>Calendar</span>
                </button>
                <button type="button" class="layout-topbar-action">
                    <i class="pi pi-inbox"></i>
                    <span>Messages</span>
                </button>
                <button type="button" class="layout-topbar-action">
                    <i class="pi pi-user"></i>
                    <span>Profile</span>
                </button>
            </div>
        </div>
    </div>
</div>
