<ng-container>
    @if (root && item.visible !== false) {
        <div class="layout-menuitem-root-text">{{ item.label }}</div>
    }

    @if ((!item.routerLink || item.items) && item.visible !== false) {
        <a [attr.href]="item.url" (click)="itemClick($event)" [ngClass]="item.styleClass" [attr.target]="item.target" tabindex="0" pRipple>
            <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{ item.label }}</span>
            @if (item.items) {
                <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
            }
        </a>
    }

    @if (item.routerLink && !item.items && item.visible !== false) {
        <a
            (click)="itemClick($event)"
            [ngClass]="item.styleClass"
            [routerLink]="item.routerLink"
            routerLinkActive="active-route"
            [routerLinkActiveOptions]="item.routerLinkActiveOptions || { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' }"
            [fragment]="item.fragment"
            [queryParamsHandling]="item.queryParamsHandling"
            [preserveFragment]="item.preserveFragment"
            [skipLocationChange]="item.skipLocationChange"
            [replaceUrl]="item.replaceUrl"
            [state]="item.state"
            [queryParams]="item.queryParams"
            [attr.target]="item.target"
            tabindex="0"
            pRipple
        >
            <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{ item.label }}</span>
            @if (item.items) {
                <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
            }
        </a>
    }

    @if (item.items && item.visible !== false) {
        <ul [@children]="submenuAnimation">
            <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
                <li menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child['badgeClass']"></li>
            </ng-template>
        </ul>
    }
</ng-container>
