import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
    messageService = inject(MessageService);

    intercept(req: HttpRequest<never>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case 400: // Handle 400 Bad Request
                        /* eslint-disable no-console */
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Bad Request',
                            detail: 'The request is invalid. Please try again.'
                        });
                        console.error('Bad Request Error (400):', error);
                        break;
                    case 404: // Handle 404 Not Found
                        console.error('Not Found Error (404):', error);
                        alert('The requested resource was not found.');
                        break;
                    case 500: // Handle 500 Internal Server Error
                        console.error('Server Error (500):', error);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Server Error',
                            detail: 'A server error occurred. Please try again later.'
                        });
                        break;
                    default: // Handle other errors
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'An unexpected error occurred. Please try again.'
                        });
                        break;
                }

                return throwError(() => error);
            })
        );
    }
}
