import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/components/layout/layout.component';
import { DashboardComponent } from './features/dashboard/dashboard';

export const appRoutes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                component: DashboardComponent
            },
            { path: 'features', loadChildren: () => import('./features/features.routes') }
        ]
    },

    { path: '**', redirectTo: '/notfound' }
];
