import { Component, ElementRef } from '@angular/core';
import { AppMenu } from '../menu/menu.component';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    imports: [AppMenu]
})
export class SidebarComponent {
    constructor(public el: ElementRef) {}
}
